import dayjs from "dayjs";
import { useEffect } from "react";
import { parsePhoneNumber as phoneParse } from "libphonenumber-js";
import { ecPayRegions, getLabelForValue, weekdaysShort } from "../data/ValueLists";
import packageInfo from "../../package.json";

export const getFileSize = (size) => {
  if (size === 0) return "0 Bytes";
  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(size) / Math.log(k));
  return `${parseFloat((size / k ** i).toFixed(2))} ${sizes[i]}`;
};

export const getFileType = (fileName) =>
  fileName.substring(fileName.lastIndexOf(".") + 1, fileName.length) || fileName;

export const sortByProperty = (arr, prop) => arr.sort((a, b) => (a[prop] > b[prop] ? 1 : -1));

/**
 * Formats a string to a currency
 */
export const formatCurrency = (value, useDecimals = true) => {
  if (value === null) {
    return "";
  }

  let newValue = `${value}`;
  if (newValue.includes("$")) {
    newValue = newValue.replace("$", "");
  }

  const parsedValue = parseFloat(newValue, 10);
  if (Number.isNaN(parsedValue)) {
    return "[invalid value]";
  }

  const formatter = new Intl.NumberFormat("en-AU", {
    style: "currency",
    currency: "AUD",
    minimumFractionDigits: useDecimals ? 2 : 0,
  });

  return formatter.format(parsedValue);
};

export const formatTimeFromInt = (time) => {
  // Timestring is how many minutes after 12am

  // If on the hour, don't show minutes
  const format = time % 60 === 0 ? "ha" : "h:mma";
  return dayjs().startOf("day").add(time, "m").format(format);
};

// Detect if clicking outside of dropdown. To close it
export const detectClickOutside = (ref, callback) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback(event);
      }
    }

    // Bind the event listener
    document.addEventListener("click", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("click", handleClickOutside);
    };
  }, [ref]);
};

export const formatTimeRange = (startTime, endTime) => {
  if (startTime === null || endTime === null) {
    return "";
  }

  // TODO if in same time period (e.g. both pm) only show time period on last

  return `${formatTimeFromInt(startTime)} - ${formatTimeFromInt(endTime)}`;
};

/**
 * Convert a string of words to be separated by hyphens
 */
export const formatAsSlug = (str) => {
  if (!str) {
    return "";
  }

  const stringWithSpecialCharactersRemoved = str.replace(/[^\w\s]/gi, "");
  return stringWithSpecialCharactersRemoved.replace(/\s+/g, "-").toLowerCase();
};

export const unique = (arr) => arr.filter((v, i, a) => a.indexOf(v) === i);

export const convertDateFormat = (dateString, oldFormat, newFormat) => {
  console.log("converting date", dateString, oldFormat, newFormat);

  if (!dayjs(dateString, oldFormat, true).isValid()) {
    return null;
  }

  const dayjsDate = dayjs(dateString, oldFormat);

  return dayjsDate.format(newFormat);
};

/**
 * Sort days by week order
 */
export const sortDays = (days, startOnMonday = true) => {
  const order = {
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
    sunday: startOnMonday ? 7 : 0,
  };

  return days.sort((a, b) => (order[a] < order[b] ? -1 : 1));
};

export const dayRange = (days) => {
  const sortedDays = sortDays(days);
  console.log("sorted days", sortedDays);

  if (sortedDays.length === 1) {
    return getLabelForValue(sortedDays[0], weekdaysShort());
  }

  // If every day
  if (sortedDays.length === 7) {
    return "every day";
  }

  if (
    sortedDays.length === 5 &&
    sortedDays.includes("monday") &&
    sortedDays.includes("tuesday") &&
    sortedDays.includes("wednesday") &&
    sortedDays.includes("thursday") &&
    sortedDays.includes("friday")
  ) {
    return "weekdays";
  }

  if (sortedDays.length === 2 && sortedDays.includes("saturday") && sortedDays.includes("sunday")) {
    return "weekends";
  }

  // If two days, show the two of them
  if (sortedDays.length === 2) {
    return `${getLabelForValue(sortedDays[0], weekdaysShort())}, ${getLabelForValue(
      sortedDays[1],
      weekdaysShort(),
    )}`;
  }

  // TODO If three or more days, check if they are sequential

  return sortedDays.map((day) => getLabelForValue(day, weekdaysShort())).join(", ");
};

export const sortAlphabetical = (a, b, property) => {
  if (property) {
    return a[property] < b[property] ? -1 : 1;
  }

  return a < b ? -1 : 1;
};

/**
 * Obscures the string. For use in sending passwords to the API
 * Copied from the partner portal. Backend will decode this
 */
export const obscureString = (string) => {
  if (!string) {
    return "";
  }

  let s = btoa(string);
  s = `ec${s}chacha`;
  s = btoa(s);
  s = s.slice(3, s.length) + s.slice(0, 3);
  s = btoa(s);
  s = btoa(s);
  return s;
};

export const printObject = (objectToPrint) => JSON.parse(JSON.stringify(objectToPrint));

/**
 * Trim something that may not be a string
 */
export const trim = (str) => {
  if (typeof str === "string" || str instanceof String) {
    return str.trim();
  }

  // Not a string, so return the original value
  return str;
};

export const getAppVersion = () =>
  `web_${packageInfo.version.substring(0, packageInfo.version.lastIndexOf("."))}`;

export const emptyObject = (value) =>
  value && Object.keys(value).length === 0 && Object.getPrototypeOf(value) === Object.prototype;

/** Check for both null and undefined * */
export const isVoid = (item) => item === null || item === undefined;

/**
 * Useful for checking whether a string has any value or is empty
 * Now works with arrays too.
 * Avoids accidentally checking falsy values like 0
 *
 * Note: false values are not considered empty
 * */
export const isEmpty = (item) => {
  if (item === null || item === undefined) {
    return true;
  }

  // empty object
  if (Object.getPrototypeOf(item) === Object.prototype) {
    return item && Object.keys(item).length === 0;
  }

  // empty array
  if (item.constructor === Array) {
    return item?.length === 0;
  }

  // empty string
  return item === "";
};

export const parsePhoneNumber = (phoneNumber) => {
  if (isEmpty(phoneNumber)) {
    return null;
  }

  try {
    return phoneParse(phoneNumber || "", "AU")?.number;
  } catch (e) {
    return null;
  }
};

export const parseURL = (url) => {
  if (isEmpty(url)) {
    return null;
  }

  let newUrl = url;

  if (!url.includes("http://") && !url.includes("https://")) {
    newUrl = `https://${url}`;
  }

  console.log(`converted ${url} into ${newUrl}`);

  return newUrl;
};

export const isURL = (value) =>
  !isEmpty(value) &&
  (value.includes("http://") || value.includes("https://")) &&
  value.includes(".");

export const validateABN = (value) => {
  if (value.length !== 11) {
    return false;
  }

  const numbers = value
    .split("")
    .map((num, index) => (index === 0 ? parseInt(num - 1, 10) : parseInt(num, 10)));

  // console.log("numbers", numbers);

  const weightings = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19];

  const weightedNumbers = numbers?.map((number, index) => number * weightings[index]);

  // console.log("weightedNumbers", weightedNumbers);

  const sum = weightedNumbers?.reduce((accum, number) => accum + number, 0);

  // console.log("sum", sum);

  const divided = sum % 89;

  // console.log("divided", divided);

  return divided === 0;
};

export const isECPayRegion = (eatclubRegion) => ecPayRegions().includes(eatclubRegion);

/**
 * Gets the closest item in the list to the given value
 * Returns the next one if it's a tie
 */
export const getClosestValueInList = (value, list) => {
  let closest = null;
  let closestDifference = 0;

  const valueAsNumber = parseFloat(value);

  // Item is already in list
  if (list.includes(valueAsNumber)) {
    return valueAsNumber;
  }

  list.forEach((item) => {
    const difference = item - valueAsNumber;
    console.log(item, valueAsNumber, difference, closest, closestDifference);
    if (closest === null) {
      closest = item;
      closestDifference = Math.abs(difference);
    } else {
      if (
        Math.abs(difference) < closestDifference ||
        (Math.abs(difference) === closestDifference && difference > 0)
      ) {
        // next item up
        closest = item;
        closestDifference = Math.abs(difference);
      }
    }
  });

  return closest;
};

/**
 * Return whether the bdm is a special dude
 * Special dudes may have additional access
 */
export const isSpecialBuddy = () => {
  const specialDudes = [
    "FC208C8F-4C81-43A8-B407-814AD22399BD", // Jeroen
    "6B62784D-712D-4D3F-9202-93A68F171827", // Wardle
    "911FD527-D946-401E-8376-332951F243D8", // Allen
    "172A6718-9D50-4321-9F1B-909A43F6271D", // Han
    "D95B2481-7457-4547-8528-80B5269C779C", // Cameron
    "D1051D10-7593-45C3-A392-D229817C90F7", // staff2 user in demo
    "38CB9AD8-F0B5-497F-96FF-BB7A728F2640", // Jean
  ];

  return specialDudes.includes(localStorage?.getItem("userId"));
};

export const nullToEmpty = (value) => {
  if (isVoid(value)) {
    return "";
  }

  return value;
};

/**
 * Join a list of words together.
 * Ignores empty strings
 */
export const joinWords = (words, delimeter = " ") => {
  if (!Array.isArray(words)) {
    return "";
  }

  return nullToEmpty(
    words
      .filter((word) => typeof word === "string")
      .map((word) => word.trim())
      .join(delimeter),
  );
};

/**
 * Remove an item from a list by its id
 */
export const removeFromList = (list, id, property = "id") => [
  ...list.filter((item) => item?.[property] !== id),
];

/**
 * Return only the objects in the array that match the value
 * Can also pass an array as the value and it'll filter to
 * values which are present in the array
 *
 * Can pass through an invert flag so it returns values that don't match instead
 */
export const filterByProperty = (array, value, property = "objectId", invert = false) => {
  if (!array || array?.length === 0 || value === undefined) {
    return [];
  }

  if (Array.isArray(value)) {
    return array?.filter((item) =>
      !invert ? value.includes(item?.[property]) : !value.includes(item?.[property]),
    );
  }

  return array?.filter((item) =>
    !invert ? item?.[property] === value : item?.[property] !== value,
  );
};

/**
 * Updates all objects that match the given property.
 * Returns the updated array with those items replaced
 */
export const updateObjectByProperty = (objects, updatedObject, property = "objectId") =>
  objects?.map((obj) => {
    if (obj?.[property] === updatedObject?.[property]) {
      return updatedObject;
    }

    return obj;
  });

/**
 * Format a date
 */
export const formatDate = (date, format = "Do") => `${dayjs(date).format(format)}`;

/**
 * Takes a string of a time, and returns it in an expected time format
 */
export const formatTimeFromString = (time, inputFormat = "hh:mm", outputFormat = "h:mma") => {
  const timeAsDayjs = dayjs(time, inputFormat);

  // If on the hour, don't show minutes
  let format = outputFormat;
  if (timeAsDayjs.format("mm") === "00") {
    format = "ha";
  }

  return timeAsDayjs.format(format);
};

export const findObjectByProperty = (objects, id, property = "objectId") =>
  objects?.find((obj) => obj?.[property] === id);

/**
 * Removes all empty (null/undefined) fields from the object
 */
export const removeEmpty = (obj) => {
  if (emptyObject(obj)) {
    return {};
  }

  return Object.fromEntries(Object.entries(obj).filter(([_key, value]) => !isEmpty(value)));
};

export const removeWhitespace = (str) => {
  if (typeof str !== "string") {
    return str;
  }

  return str.replace(/\s/g, "");
};

export const pluralise = (string, number, pluralString) => {
  if (number === 1) {
    return string;
  }

  if (pluralString) {
    return pluralString;
  }

  return `${string}s`;
};

export const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

/**
 * Dynamically load a script from a component, to avoid needing to manually
 * place it in the
 * index.html file (meaning we only load it if this function is called)
 *
 * Must pass a check function to prevent the script being loaded multiple times
 */
export const loadScript = (url, checkFunction) => {
  if (checkFunction()) {
    console.log("Script already loaded");
    return;
  }

  const script = document.createElement("script"); // create script tag
  script.type = "text/javascript";

  // when script state is ready and loaded or complete we will call callback
  if (script.readyState) {
    script.onreadystatechange = () => {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
      }
    };
  }

  script.src = url; // load by url
  document.getElementsByTagName("head")[0].appendChild(script); // append to head
  console.log("New script loaded");
};

export const isObject = (object) => typeof object === "object";

/**
 * Transforms the given address details into a single address string
 */
export const getLongAddress = (address, suburb, state, postcode, country, delimeter = ", ") => {
  // Optionally allow sending through an address object
  if (isObject(address)) {
    return joinWords(
      [address?.address, address?.suburb, address?.state, address?.postcode, address?.country],
      delimeter,
    );
  }

  return joinWords([address, suburb, state, postcode, country], delimeter);
};

/**
 * Note: Do not use the regex from this post, it's not correct
 * https://stackoverflow.com/questions/46155/how-can-i-validate-an-email-address-in-javascript
 *
 * Instead we want a more basic check that just allows for x@x.x or x@x.x.x where any of the x's are any characters.
 * This might miss some emails that are technically invalid, but prevents many false positives
 *
 * @param email
 * @returns {RegExpMatchArray}
 */
export const isValidEmail = (email) => String(email).match(/^[^\s@]+@[^\s@]+([.][^\s@]+)+$/);
