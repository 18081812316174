import PropTypes from "prop-types";
import React, { useState } from "react";
import { Box } from "@mui/material";
import { OpeningHoursDay } from "./OpeningHoursDay";
import { weekdays } from "../../data/ValueLists";
import { getTimes } from "../../helpers/Times";
import "./OpeningHoursStyles.css";
import { formStateType } from "../../types";

export const OpeningHours = ({
  className,
  style,
  value,
  formState,
  setFieldValue,
  setFieldValues,
  disabled,
}) => {
  /**
   * Return true if any of the days have start hours filled out
   */
  const wereStartHoursChosen = () =>
    // Filter to all days that have a start time set
    Object.keys(formState.openingHours).filter((key) => {
      const dayHours = formState.openingHours[key]?.shifts || [];
      // filter to all shifts that have a start time set
      return dayHours?.filter((shift) => shift.start !== null).length !== 0;
    }).length !== 0;

  /**
   * Return true if any of the days have start hours filled out
   */
  const wereEndHoursChosen = () =>
    // Filter to all days that have an end time set
    Object.keys(formState.openingHours).filter((key) => {
      const dayHours = formState.openingHours[key]?.shifts || [];
      // filter to all shifts that have an end time set
      return dayHours.filter((shift) => shift.end !== null).length !== 0;
    }).length !== 0;

  /**
   * Return true if any of the days have start hours filled out for split shift
   */
  const wereSplitStartHoursChosen = () => {
    // Filter to all days that have a start time set
    console.log(
      "split",
      formState.openingHours,
      Object.keys(formState.openingHours).filter(
        (key) =>
          formState.openingHours[key]?.shifts?.[1]?.start !== 0 &&
          formState.openingHours[key]?.shifts?.[1]?.start !== undefined,
      ),
    );
    return (
      Object.keys(formState.openingHours).filter(
        (key) =>
          formState.openingHours[key]?.shifts?.[1]?.start !== 0 &&
          formState.openingHours[key]?.shifts?.[1]?.start !== undefined,
      ).length !== 0
    );
  };

  /**
   * Return true if any of the days have start hours filled out
   */
  const wereSplitEndHoursChosen = () =>
    // Filter to all days that have a start time set
    // console.log(formState.openingHours);
    Object.keys(formState.openingHours).filter(
      (key) =>
        formState.openingHours[key]?.shifts?.[1]?.end !== 0 &&
        formState.openingHours[key]?.shifts?.[1]?.end !== undefined,
    ).length !== 0;

  const [startHoursChosen, setStartHoursChosen] = useState(wereStartHoursChosen());
  const [endHoursChosen, setEndHoursChosen] = useState(wereEndHoursChosen());

  const [splitStartHoursChosen, setSplitStartHoursChosen] = useState(wereSplitStartHoursChosen());
  const [splitEndHoursChosen, setSplitEndHoursChosen] = useState(wereSplitEndHoursChosen());

  const setOpeningHours = (day, property, newValue) => {
    setFieldValue(`openingHours.${day}.${property}`, newValue);

    // Set all starting shifts if not already
    if (property === "shifts" && newValue[0].start !== null && !startHoursChosen) {
      const startTime = newValue[0].start;
      setFieldValues(
        weekdays().map((weekday) => ({
          name: `openingHours.${weekday.value}.shifts.0.start`,
          value: startTime,
        })),
      );
      setStartHoursChosen(true);
    }

    // Set all ending shifts if not already
    if (property === "shifts" && newValue[0].end !== null && !endHoursChosen) {
      const endTime = newValue[0].end;
      setFieldValues(
        weekdays().map((weekday) => ({
          name: `openingHours.${weekday.value}.shifts.0.end`,
          value: endTime,
        })),
      );
      setEndHoursChosen(true);
    }

    // And for split shifts
    console.log(
      "new start",
      property,
      newValue?.[1],
      splitStartHoursChosen,
      property === "shifts" && newValue?.[1]?.start !== null && !splitStartHoursChosen,
    );
    if (property === "shifts" && newValue?.[1]?.start !== null && !splitStartHoursChosen) {
      const startTime = newValue[1].start;
      setFieldValues(
        weekdays().map((weekday) => ({
          name: `openingHours.${weekday.value}.shifts.1.start`,
          value: startTime,
        })),
      );
      setSplitStartHoursChosen(true);
    }

    // Set all ending split shifts if not already
    if (property === "shifts" && newValue?.[1].end !== null && !splitEndHoursChosen) {
      const endTime = newValue[1].end;
      setFieldValues(
        weekdays().map((weekday) => ({
          name: `openingHours.${weekday.value}.shifts.1.end`,
          value: endTime,
        })),
      );
      setSplitEndHoursChosen(true);
    }
  };

  console.log("fd", formState);

  return (
    <Box className={`opening-hours-container ${className}`} style={style}>
      {weekdays().map((weekday) => (
        <OpeningHoursDay
          day={weekday.label}
          key={weekday.value}
          getTimes={getTimes}
          onChange={(property, value) => setOpeningHours(weekday.value, property, value)}
          value={value[weekday.value]}
          setFieldValue={setFieldValue}
          disabled={disabled}
        />
      ))}
    </Box>
  );
};

OpeningHours.defaultProps = {
  className: "",
  style: "",
  defaultValue: {
    monday: [{ start: null, end: null, id: 0 }],
    tuesday: [{ start: null, end: null, id: 0 }],
    wednesday: [{ start: null, end: null, id: 0 }],
    thursday: [{ start: null, end: null, id: 0 }],
    friday: [{ start: null, end: null, id: 0 }],
    saturday: [{ start: null, end: null, id: 0 }],
    sunday: [{ start: null, end: null, id: 0 }],
  },
};

OpeningHours.propTypes = {
  className: PropTypes.string,
  style: PropTypes.shape({}),
  defaultValue: PropTypes.shape({}),
  value: PropTypes.shape({}).isRequired,
  formState: formStateType.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};
