import { useRef } from "react";
import { useLocation } from "react-router-dom";

export const useFocus = () => {
  const htmlElRef = useRef(null);
  const setFocus = (keepFocus = true) =>
    keepFocus ? htmlElRef.current?.focus() : htmlElRef.current?.blur();
  return [htmlElRef, setFocus];
};

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}
